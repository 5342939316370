// src/components/LoadingPage/LoadingPage.js
import React, { useMemo } from 'react';
import {
  Loader,
  LoadingText,
  ProgressBar,
  Progress,
  Percentage,
  LoaderWrapper
} from './StyledLoadingPage';
import RotatingImageWrapper from './RotatingImageWrapper';
import { useLoading } from './LoadingContext';

const LoadingPage = () => {
  const { progress, loadingStatus } = useLoading();

  const progressStyle = useMemo(() => ({ width: `${progress}%` }), [progress]);

  return (
    <LoaderWrapper $loadingStatus={loadingStatus}>
      <RotatingImageWrapper
        width="5vw"
        height="8vh"
        className="logotLoading"
      />
      <Loader $progress={progress}>
        <LoadingText $progress={progress}>PuraMinal Shilajit...</LoadingText>
        <ProgressBar $fullWidth={loadingStatus === 'barFull'}>
          <Progress style={progressStyle} />
        </ProgressBar>
        <Percentage $progress={progress}>{progress}%</Percentage>
      </Loader>
    </LoaderWrapper>
  );
};

export default React.memo(LoadingPage);
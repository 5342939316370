// src/contexts/InitialLoadingContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const InitialLoadingContext = createContext();

export const InitialLoadingProvider = ({ children }) => {
  const [isInitialLoadingComplete, setIsInitialLoadingComplete] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('loading'); // 'loading', 'barFull', 'complete'

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoadingComplete(true);
      setLoadingStatus('complete'); // Mettez à jour l'état de chargement ici pour synchroniser
    }, 5000); // Délai pour s'assurer que LoadingPage est initialisé

    return () => clearTimeout(timer);
  }, []);

  return (
    <InitialLoadingContext.Provider value={{ isInitialLoadingComplete, loadingStatus, setLoadingStatus }}>
      {children}
    </InitialLoadingContext.Provider>
  );
};

export const useInitialLoading = () => useContext(InitialLoadingContext);

// src/data/packs.js
import imagePack1 from '../assets/image/imagePack1.jpg';
import imagePack2 from '../assets/image/imagePack2.jpg';

const packs = [
  { id: 1, title: '1x Pot - 10g', price:29.99, quantity: 1, imageUrl: imagePack1 , durationTitle: '1 mois'},
  { id: 2, title: '2x Pots - 10g', price: 59.98, quantity: 1, imageUrl: imagePack2 , durationTitle: '2 mois' },
];

export default packs;

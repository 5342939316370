// src/redux/action/CartActions.js
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const CLEAR_CART = 'CLEAR_CART'; // Ajoutez cette action

// Action pour ajouter un article au panier
export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: item,
});

// Action pour supprimer un article du panier
export const removeFromCart = (id) => ({
  type: REMOVE_FROM_CART,
  payload: id,
});

// Action pour mettre à jour la quantité d'un article dans le panier
export const updateQuantity = (id, quantity) => ({
  type: UPDATE_QUANTITY,
  payload: { id, quantity },
});

// Action pour vider le panier
export const clearCart = () => ({
  type: CLEAR_CART,
});
// src/components/LoadingPage/LoadingContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState('loading'); // 'loading', 'barFull', 'complete'

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        } else {
          setLoadingStatus('barFull');
          setTimeout(() => {
            setLoadingStatus('complete');
            setLoading(false);
          }, 1000); // Délai de 0,5 seconde après que la barre de chargement soit pleine

          clearInterval(intervalId);
          return 100;
        }
      });
    }, 20); // Ajuster la vitesse d'incrémentation si nécessaire

    return () => clearInterval(intervalId);
  }, []);

  return (
    <LoadingContext.Provider value={{ progress, loading, loadingStatus }}>
      {children}
    </LoadingContext.Provider>
  );
};

// src/redux/store/store.js
import { createStore } from 'redux';
import rootReducer from '../redux/reducers/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Configuration de la persistance
const persistConfig = {
  key: 'root',
  storage,
};

// Réducteur persistant
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Création du store Redux
const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
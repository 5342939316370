// src/redux/reducers/index.js
import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import packReducer from './packReducer'; // Importer le nouveau réducteur

const rootReducer = combineReducers({
  cart: cartReducer,
  pack: packReducer, // Ajouter le nouveau réducteur
});

export default rootReducer;

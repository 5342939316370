// src/AppWithLoading.js
import React, { useState, useEffect, Suspense, lazy } from 'react';
import LoadingPage from './components/LoadingPage/LoadingPage';
import { InitialLoadingProvider, useInitialLoading } from './contexts/InitialLoadingContext';
import { LoadingProvider } from './components/LoadingPage/LoadingContext';

const LazyApp = lazy(() => import('./App'));

const AppWithLoadingContent = React.memo(() => {
  const { isInitialLoadingComplete } = useInitialLoading();
  const [isLazyLoadingEnabled, setIsLazyLoadingEnabled] = useState(false);

  useEffect(() => {
    console.time('LazyApp Load Time');
    const timer = setTimeout(() => {
      setIsLazyLoadingEnabled(true);
    }, 2070); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {!isInitialLoadingComplete && <LoadingPage />}
      {isLazyLoadingEnabled && (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyApp />
        </Suspense>
      )}
    </>
  );
});

const AppWithLoading = () => (
  <InitialLoadingProvider>
    <LoadingProvider>
      <AppWithLoadingContent />
    </LoadingProvider>
  </InitialLoadingProvider>
);

export default AppWithLoading;
// RotatingImageWrapper.js
import React from 'react';
import styled from 'styled-components';
import LogotPuramina from '../../assets/image/LogotPuraminal.svg';

const StyledImage = styled.img`
  object-fit: cover;
  display: block;
  border-radius: 50px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -30%);
  width: 5vw;
  height: 5vw;
  margin: 0;

  @media (max-width: 1250px) {
    top: 34%;

    width: 7vw;
    height: 7vw;
  }
  @media (max-width: 850px) {
    width: 7vw;
    height: 7vw;
    top: 37%;

  }

  @media (max-width: 550px) {
    width: 12vw;
    height: 12vw;
    top: 36%;

  }
`;

const RotatingImageWrapper = ({ width, height, className }) => {
  return (
    <StyledImage
      src={LogotPuramina}
      alt="logot"
      width={width}
      height={height}
      className={className}
    />
  );
};

export default RotatingImageWrapper;

// src/redux/reducers/packReducer.js
import { SELECT_PACK } from '../action/PackActions';
import packs from '../../data/Packs';

const initialState = {
  selectedPack: packs[0], // Pack par défaut
};

const packReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PACK:
      return {
        ...state,
        selectedPack: action.payload,
      };
    default:
      return state;
  }
};

export default packReducer;
